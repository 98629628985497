export default {
  title: '扫码赢5.88元红包',
  // 加载远程图
  loadRemotePic: true,
  // 埋点配置
  tracking: {
    url: process.env.VUE_APP_BASE_LOG + '/osa/logAgent/save',
    scene: {
      //页面进入前
      before: {
        // 路由名称
        Front: {},
        Middle: {},
        Back: {}
      },
      //页面进入后
      after: {},
      //返回页面
      back: {},
      //页面所有接口请求后埋点
      nextRequest: {}
    },
    // 转换页面埋点
    transform(routeName) {
      return routeName
    },
    hook(type, obj) {
      let typeMapping = {
        // 来自v-tracking
        click() {
          console.log('click::', obj.routeName)
        }
      }
      typeMapping[type] && typeMapping[type]()
    }
  }
}
