import { createRouter, createWebHistory } from 'vue-router'
import Auth from '../views/auth/index.vue'
import { addWxConfig } from '@/utils/wxConfig.js'
import setting from '@/setting.js'

const routes = [
  {
    path: '/:qrCode/:projectCode',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/index')
  },
  {
    path: '/activity-status',
    name: 'ActivityStatus',
    component: () =>
      import(/* webpackChunkName: "activityStatus" */ '../views/activity-status/index')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_ROUTER_BASE),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  document.title = (to.meta && to.meta.title) || setting.title
  next()
})

router.afterEach((to) => {
  if (to.name != 'Auth') {
    addWxConfig()
  }
})

export default router
