import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// Toast
import 'vant/es/toast/style'

// v-bg
import bg from './plugins/bg'
// 埋点插件
import tracking from './plugins/tracking'

import { get } from '@/utils/index'

const app = createApp(App)
app.use(bg)
app.use(tracking, {
  router
})
app.config.globalProperties.$get = get
app.use(store)
app.use(router)
app.mount('#app')
