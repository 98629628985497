import "core-js/modules/es.array.push.js";
import { stringify } from 'qs';
import { mapGetters } from 'vuex';
import { checkProject, checkCode, wxCode, userinfo, location } from '@/api/common.js';
import { setSessionStorage } from '@/utils/storage.js';
import { addWxConfig } from '@/utils/wxConfig.js';
import setting from '@/setting.js';
const wx = require('weixin-js-sdk');
export default {
  name: 'Auth',
  data() {
    return {
      code: '',
      isLoading: false
    };
  },
  computed: {
    ...mapGetters(['projectCode', 'qrCode', 'isLocation'])
  },
  watch: {
    '$route.query.code': {
      handler: function (code) {
        let {
          projectCode,
          qrCode
        } = this.$route.params;
        if (projectCode && qrCode) {
          this.$store.commit('projectCode', projectCode);
          this.$store.commit('qrCode', qrCode);
        }
        if (code) {
          this.isLoading = true;
          this.code = code;
          let redirect = window.location.protocol + '//' + window.location.host + '' + window.location.pathname;
          setSessionStorage('_redirect', redirect);

          // 获取用户信息
          this.getUserinfo();
        } else {
          if (!projectCode && !qrCode) {
            return;
          }
          // 进入页面埋点
          this.$sendTracking({
            projectCode,
            qrCode,
            userVisit: 13
          });
          this.getCheckProject();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 获取项目校验
    getCheckProject() {
      checkProject({
        projectCode: this.projectCode
      }).then(({
        data
      }) => {
        //0=活动未开始，1=活动已开始，2=活动已结束，3=活动不存在，4=活动状态未知
        if (data.status == 1) {
          let isLocation = data.location;
          this.$store.commit('isLocation', isLocation);
          // 获取码
          this.getCheckCode();
        } else {
          //0未开始 2已结束
          this.$router.push({
            name: 'ActivityStatus',
            query: {
              status: data.status
            }
          });
        }
      });
    },
    // 获取码
    getCheckCode() {
      checkCode({
        qrCode: this.qrCode
      }).then(({
        data
      }) => {
        // 0正确数据 1已关联待激活 2已激活 待进入活动10 活动99 无效
        if (data.status == 10) {
          // 获取微信code
          this.getWxCode();
        } else {
          data._title = setting.title;
          const codeStateURL = {
            1: `${process.env.VUE_APP_QCODESTATE_URL}?${stringify(data)}`,
            0: `${process.env.VUE_APP_CODESTATE_URL}?${stringify(data)}`
          };
          const verifyPageType = data.verifyPageType;
          if (verifyPageType) {
            window.location.href = codeStateURL[verifyPageType];
          }
        }
      });
    },
    // 获取微信code
    getWxCode() {
      wxCode({
        projectCode: this.projectCode,
        qrCode: this.qrCode
      }).then(({
        data
      }) => {
        console.log(data);
        let appId = data.appID;
        let redirectUri = encodeURIComponent(window.location.href.split('#')[0]);
        let scope = data.scope;
        let httpUrl = data.oauthCodePrefix;
        let state = this.projectCode + '/' + this.qrCode;
        let url = `${httpUrl}appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
        console.log(url);
        window.location.href = url;
      });
    },
    // 获取用户信息
    getUserinfo() {
      userinfo({
        code: this.code
      }).then(({
        data
      }) => {
        this.$store.commit('openId', data.openId);
        // 获取微信定位所需参数
        this.getLocationParams();
      });
    },
    // 获取微信定位所需参数
    getLocationParams() {
      console.log('get isLocation', this.isLocation);
      if (this.isLocation) {
        addWxConfig().then(() => {
          wx.getLocation({
            debug: true,
            type: 'wgs84',
            // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            success: ({
              latitude,
              longitude
            }) => {
              this.getLocation(latitude, longitude);
            },
            cancel: () => {
              console.log('取消 getLocation');
              this.getLocation();
            },
            fail: () => {
              console.log('失败 getLocation');
              this.getLocation();
            }
          });
        });
      } else {
        this.toWhichPage();
      }
    },
    // 根据经纬度获取位置信息
    getLocation(lat = '', lng = '') {
      location({
        lat,
        lng
      }).then(() => {
        this.toWhichPage();
      });
    },
    //去哪个转盘
    toWhichPage() {
      this.$router.push({
        name: 'Home'
      });
    }
  }
};